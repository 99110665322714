import { MessageResponse } from '~/feature/auth/types'
import { getApiClient } from '~/lib/apiClientSingleton'

const logout = async () => {
  const endpoint = '/session'
  const apiClient = getApiClient()
  return await apiClient.fetchFromAPI<MessageResponse>({
    endpoint,
    options: apiClient.deleteRequestOptions(),
  })
}

export default logout