import { type GetLoginUserResponse } from '@IJGN/potential'
import { getApiClient } from '~/lib/apiClientSingleton'

const getLoginUser = async (): Promise<GetLoginUserResponse> => {
  const apiClient = getApiClient()
  return await apiClient.fetchFromAPI<GetLoginUserResponse>({
    endpoint: '/me',
    options: apiClient.getRequestOptions(),
  })
}

export default getLoginUser
