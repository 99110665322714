import '~/styles/style.css'
import { useAuth } from '@IJGN/potential'
import { useIsAdmin } from '~/lib/role'
import LogoPath from './images/logo/logo-potential-cloud.png'
import BasicButton from './routes/components/Button/BasicButton'

export default function NotFound() {
  const { user } = useAuth()
  const isAdmin = useIsAdmin()

  const handleClick = () => {
    if (user) {
      window.location.replace('/')
    } else {
      window.location.replace('/login')
    }
  }

  return (
    <main
      className={`l-management-screen__main flex flex-col gap-[50px] justify-center items-center p-10 w-full h-full relative before:absolute before:content-[""] before:w-full before:h-5 before:top-0 before:right-0 before:z-10 before:bg-gradient-to-r before:from-potential-blue-1 before:to-excellent-red-1
      ${isAdmin ? 'bg-greige-5' : 'bg-knowledge-navy-5'}
    `}
    >
      <img src={LogoPath} alt='POTENTIAL CLOUD' className='w-[274px] block' />
      <div className='shadow-xl bg-white rounded-3xl overflow-hidden w-full max-w-2xl'>
        <div className='block p-10 w-full'>
          <h1 className='text-xl text-center font-bold mb-5'>404 Not Found</h1>
          <p className='flex justify-center text-base text-gray-2 font-medium text-center'>
            お探しのページは見つかりませんでした。
            <br />
            大変お手数ですが、
            {user ? 'トップページ' : 'ログイン'}
            からお戻りください。
          </p>
        </div>
        <div className='flex justify-end items-center gap-2.5 p-5 bg-gray-5 border-t border-solid border-gray-4'>
          {/* Link を使用するとcss, jsのローディングが上手くいかない */}
          <BasicButton
            label={`${user ? 'トップページへ' : 'ログインページへ'}`}
            onClick={handleClick}
            colorVariant='link-move'
            largeSize
          />
        </div>
      </div>
    </main>
  )
}
