import type { LinksFunction } from '@remix-run/node'
import { Links, Meta, type MetaFunction, Outlet, Scripts, ScrollRestoration, useLocation } from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type React from 'react'
import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ToastContainer } from 'react-toastify'
import Forbidden from './forbidden'
import 'react-toastify/dist/ReactToastify.css'
import loginUserQueryKeys from '~/feature/me/queryKeys/loginUserQueryKeys'
import * as gtag from '~/lib/gtags.client'
import AuthContainer from './AuthContainer'
import { LogProvider } from './context/LogContext'
import getLoginUser from './feature/me/api/getLoginUser'
import Favicon from './images/meta/favicon.ico'
import { SITE_TITLE } from './lib/constants'
import NotFound from './notFound'
import ErrorFallback from './routes/components/Error/ErrorFallback'
import './styles/style.css'
import { AuthProvider, handleQueryError } from '@IJGN/potential'
import { DomainFloatingMessage } from './components/DomainFloatingMessage'
import { SharedDataProvider } from './context/SharedDataProvider'
import { initNewRelic, noticeNewRelicError } from './newRelicUtils'

export const meta: MetaFunction = () => {
  return [
    { title: SITE_TITLE },
    {
      property: 'og:title',
      content: SITE_TITLE,
    },
    {
      property: 'og:description',
      content: SITE_TITLE,
    },
  ]
}
export const links: LinksFunction = () => {
  return [
    {
      rel: 'icon',
      href: Favicon,
      type: 'image/icon',
    },
  ]
}

export function Layout({ children }: { children: React.ReactNode }) {
  const onError = (error: Error, info: { componentStack?: string | null; digest?: string | null }) => {
    console.log('error.message', error.message)
    console.log('info.componentStack:', info.componentStack)

    // Sentry
    captureRemixErrorBoundaryError(error)

    // New Relic
    noticeNewRelicError(error)
  }

  const [errorType, setErrorType] = useState<string | null>(null)
  const errorComponents: { [key: string]: JSX.Element } = {
    NotFound: <NotFound />,
    Forbidden: <Forbidden />,
  }

  const location = useLocation()

  // ログイン（システム管理者・企業担当者）
  // ヘッダーとサイドバーを表示しない

  // React Query内で発生したエラーはすべてここでキャッチされる
  /**
   * React Queryのエラーハンドリング
   *
   * @see https://tanstack.com/query/latest/docs/reference/QueryCache
   *
   * @example **エラーを無視させたいとき**
   * ```ts
   *  const useXXXXXXX = useQuery({
   *  queryKey: [queryKeys.XXXXX],
   *    queryFn: () => getXXXXX(),
   *    meta: { isErrorHandling: false },
   *  })
   * ```
   */
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        // meta.isErrorHandlingがfalseの場合はエラーを無視する
        if (query.meta?.isErrorHandling === false) {
          return
        }
        if (handleQueryError(error, setErrorType)) return
      },
    }),
  })

  useEffect(() => {
    // Google Analytics
    if (import.meta.env.VITE_GA_ID && !document.getElementById('gtag-init')) {
      const gtagScript = document.createElement('script')
      gtagScript.innerHTML = `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${import.meta.env.VITE_GA_ID}', {
                          page_path: window.location.pathname,
                        });
                      `
      gtagScript.id = 'gtag-init'
      document.head.appendChild(gtagScript)
      const tabManagerScript = document.createElement('script')
      tabManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_GA_ID}`
      tabManagerScript.async = true
      document.head.appendChild(tabManagerScript)
    }

    // New Relic
    initNewRelic()
  }, [])

  useEffect(() => {
    if (import.meta.env.VITE_GA_ID) {
      gtag.pageView(location.pathname, import.meta.env.VITE_GA_ID)
    }
  }, [location])

  // ドメインを取得
  const [domain, setDomain] = useState<string | null>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        window.location.hostname.indexOf('localhost') !== -1 ||
        window.location.hostname.indexOf('127.0.0.1') !== -1 ||
        window.location.hostname.indexOf('.local') !== -1
        //MEMO: ローカル環境で他ドメインで動作させている方はここに追加してください
      ) {
        setDomain('local')
      } else if (window.location.hostname.indexOf('staging') !== -1) {
        setDomain('staging')
      }
    }
  }, [])

  const isSlidePage = location.pathname.includes('/report/slide')

  return (
    <ErrorBoundary onError={onError} FallbackComponent={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider loginUserQueryKeys={loginUserQueryKeys} getLoginUser={getLoginUser}>
          <SharedDataProvider>
            <LogProvider>
              <html lang='ja'>
                <head>
                  <meta charSet='utf-8' />
                  <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
                  <meta name='robots' content='noindex' />
                  <Meta />
                  <Links />
                  <script src='https://kit.fontawesome.com/72469cc36c.js' crossOrigin='anonymous' />
                </head>
                <body className='l-management-screen h-screen bg-white'>
                  <>
                    {errorType ? (
                      errorComponents[errorType] || null
                    ) : (
                      <>
                        <AuthContainer>{children}</AuthContainer>
                        <ToastContainer key={location.pathname} />
                        {(domain === 'local' || domain === 'staging') && !isSlidePage && (
                          <DomainFloatingMessage type={domain} />
                        )}
                      </>
                    )}
                    <ScrollRestoration />
                    <Scripts />
                  </>
                </body>
              </html>
            </LogProvider>
          </SharedDataProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default function App() {
  return <Outlet />
}
