import { useAuth } from '@IJGN/potential'
import { Link } from '@remix-run/react'
import { useQuery } from '@tanstack/react-query'
import { type ChangeEvent, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSharedData } from '~/context/SharedDataProvider'
import getCompanies from '~/feature/companies/api/getCompanies'
import companiesQueryKeys from '~/feature/companies/queryKeys/companiesQueryKeys'
import useLocalStorage from '../hooks/useLocalStorage'
import LogoPath from '../images/logo/logo-potential-cloud.png'
import { type Company, CompanyStatusSchema } from '../models/company'
import { useIsAdmin } from '~/lib/role'

export default function Header() {
  // ポップアップ
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const popupRef = useRef<HTMLDivElement>(null)
  const { user } = useAuth()
  const isAdmin = useIsAdmin()
  const { company } = useSharedData()

  const [isDisplayHeader, , loading] = useLocalStorage<boolean>('shownHeader', true)

  // useLocationフックを使用して現在のURLを取得
  const location = useLocation()
  const { company: company_code } = useParams<{ company: string }>()

  // 現在のページについて
  // MEMO: 以下、システム管理
  // ダッシュボード
  const pageSystemDashboard = location.pathname === '/admin/dashboard'
  // システム管理
  const pageCompanyManagement =
    location.pathname === '/companies' ||
    location.pathname.startsWith('/companies/new') ||
    location.pathname.startsWith(`/companies/${company_code}/edit`)
  // 質問マスター管理
  const pageMasterQuestionsets = location.pathname.startsWith('/question_sets')
  // コンサルタント管理
  const pageAdmins = location.pathname.startsWith('/admin')
  // マイページ
  const pageAdminMypage = location.pathname.startsWith('/admin/mypage')

  // MEMO: 以下、企業コンサルティング
  const pageCompanyConsulting =
    location.pathname.startsWith('/companies/consulting') ||
    (location.pathname.startsWith(`/companies/${company_code}/`) &&
      !location.pathname.startsWith(`/companies/${company_code}/edit`))

  const [companies, setCompanies] = useState<Company[]>([])
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([])

  const useCompanies = useQuery({
    queryKey: companiesQueryKeys.list(),
    queryFn: async () => {
      const data = await getCompanies({
        company_statuses: CompanyStatusSchema.enum.active,
      })
      setCompanies(data.companies)
      return data
    },
    enabled: isAdmin,
  })

  useEffect(() => {
    // NOTE: 企業登録・編集後、一覧に戻った時に、企業コンサルティングプルダウン内の企業一覧が最新の情報に更新されないので、ここで強制再取得させている。
    // NOTE: 登録・更新処理時にクエリキーを無効化する方法では、ヘッダー自体が再マウントされないのでうまくいかない。
    if (location.pathname === '/companies') {
      useCompanies.refetch()
    }
  }, [location.pathname, useCompanies.refetch])

  useEffect(() => {
    setFilteredCompanies(companies)

    const handleOutsideClick = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isOpen, companies])

  const openPopup = () => {
    setIsOpen(true)
  }

  const closePopup = () => {
    setIsOpen(false)
  }

  // 企業コンサルティング
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.trim().toLowerCase()
    if (searchText === '') {
      // 空白の場合はすべての企業を表示
      setFilteredCompanies(companies)
    } else {
      const filtered = companies.filter(company => company.name.toLowerCase().includes(searchText))
      setFilteredCompanies(filtered)
    }
  }

  return (
    <>
      {!loading && (
        <div
          className={`l-management-screen__header border-b border-solid border-gray-2 bg-gray-6 flex justify-between items-center p-5 h-24 ${
            !loading && !isDisplayHeader ? 'hidden' : ''
          }`}
        >
          <Link
            to={`${isAdmin ? '/admin/dashboard' : user && company ? `/companies/${company.code}/dashboard` : '/'}`}
            className='flex gap-2.5 items-center'
          >
            <div className='flex gap-2.5 items-center'>
              <img src={LogoPath} alt='POTENTIAL CLOUD' className='h-[40px] w-auto' />
            </div>
            {isAdmin && (
              <span className='font-bold text-xs px-1 pt-1 pb-0.5 bg-excellent-red-1 text-white rounded'>
                管理者向け
              </span>
            )}
          </Link>
          <div className='h-9 justify-start items-center gap-5 flex'>
            {isAdmin && (
              <>
                <div className='rounded-lg flex-col justify-center items-start flex'>
                  <Link
                    to='/companies/consulting'
                    className={`text-sm font-medium ${
                      pageCompanyConsulting
                        ? 'text-potential-blue-1 pointer-events-none underline underline-offset-8'
                        : 'text-gray-1'
                    }`}
                  >
                    企業コンサルティング
                  </Link>
                </div>
                <div
                  className='w-[360px] h-9 justify-center items-center flex'
                  role='button'
                  onClick={openPopup}
                  tabIndex={0}
                  onKeyUp={openPopup}
                >
                  <div className='h-9 justify-center items-center flex w-full'>
                    <div
                      className={`relative h-9 w-full px-3 py-2.5 bg-white rounded-lg shadow border border-gray-4 justify-between items-center flex ${
                        isOpen && 'shadow border-potential-blue-1 outline outline-4 outline-potential-blue-4'
                      }`}
                      role='button'
                      onClick={openPopup}
                      tabIndex={0}
                      onKeyUp={openPopup}
                    >
                      <div
                        className={`text-xs font-medium clamp-text ${
                          pageCompanyConsulting && company?.name ? 'text-gray-1' : 'text-gray-2'
                        }`}
                      >
                        <>
                          {pageCompanyConsulting && (
                              <>
                                {company?.status ===
                                CompanyStatusSchema.enum.preparing
                                    ? '[準備中]'
                                    : company?.status ===
                                    CompanyStatusSchema.enum.inactive
                                        ? '[利用終了]'
                                        : ''}{' '}
                              </>
                          )}
                          </>
                        {pageCompanyConsulting ? company?.name || '企業を選択してください' : '企業を選択してください'}
                      </div>
                      <div className='flex justify-center items-center icon-before icon-regular icon-angles-up-down text-xs text-gray-2' />
                      {/* 選択項目 */}
                      {isOpen && (
                        <div
                          className='absolute top-9 left-0 pt-1 z-[100]'
                          onClick={e => e.stopPropagation()}
                          onKeyUp={e => e.stopPropagation()}
                          tabIndex={0}
                          role='button'
                          ref={popupRef}
                        >
                          <div className='w-[360px] bg-white rounded-xl shadow border border-gray-4 flex-col justify-start items-start flex'>
                            <div className='self-stretch h-14 px-2 py-2.5 flex-col justify-start items-start flex cursor-default'>
                              <div className='self-stretch h-9 flex-col justify-start items-start gap-2 flex'>
                                <div className='self-stretch bg-white rounded-lg shadow border border-gray-4 justify-start items-center flex'>
                                  <div className='pl-3 py-2.5 justify-start items-center gap-2 flex text-gray-1'>
                                    <span className='flex justify-center items-center w-full h-full icon-before icon-light text-sm icon-search' />
                                  </div>
                                  <input
                                    className='justify-center items-center flex placeholder:text-gray-2 text-xs font-medium w-full px-3 py-2.5'
                                    placeholder='企業を選択してください'
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                    tabIndex={0}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='self-stretch max-h-[184px] flex-col justify-start items-start flex cursor-default'>
                              <div className='self-stretch px-3 pt-4 justify-start items-start flex'>
                                <div className='text-gray-2 text-xs font-medium pb-[2px]'>
                                  コンサルタントとして操作する企業を選択：
                                </div>
                              </div>
                              <div className='max-h-96 w-full px-2 pt-[6px] pb-2 overflow-y-auto scrollbar scrollbar-thumb-rounded scrollbar-thumb-black'>
                                {filteredCompanies.map(company => (
                                  <Link
                                    to={`/companies/${company.code}/dashboard`}
                                    className={`self-stretch px-3 py-2 rounded-lg justify-between items-center gap-3 flex w-full hover:bg-gray-4 text-gray-1 ${
                                      (String(company.code) === String(company_code)) && pageCompanyConsulting
                                        ? 'bg-gray-4 pointer-events-none'
                                        : 'bg-white'
                                    }`}
                                    key={company.id}
                                    onClick={() => {
                                      String(company.code) !== String(company_code) && closePopup()
                                    }}
                                  >
                                    <span
                                      className='h-5 justify-start items-center gap-2.5 flex clamp-text'
                                      title={company.name}
                                      aria-label={company.name}
                                    >
                                      {company.name}
                                    </span>
                                    {(String(company.code) === String(company_code)) && pageCompanyConsulting && (
                                      <span className='flex justify-center items-center icon-before icon-light text-sm icon-check' />
                                    )}
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='w-px h-6 bg-gray-4' />
                <div className='rounded-lg flex-col justify-center items-start flex'>
                  <Link
                    to='/admin/dashboard'
                    className={`text-sm font-medium ${
                      pageSystemDashboard ||
                      pageCompanyManagement ||
                      pageMasterQuestionsets ||
                      pageAdmins ||
                      pageAdminMypage
                        ? 'text-potential-blue-1 pointer-events-none underline underline-offset-8'
                        : 'text-gray-1'
                    }`}
                  >
                    システム管理
                  </Link>
                </div>
              </>
            )}
            {user && !isAdmin && (
              <div className='rounded-lg flex-col justify-center items-start flex'>
                <div className='text-gray-1 text-sm font-medium'>{company?.name}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
