import type React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import './styles/style.css'
import { removeLatestAdminLogin, setLatestAdminLogin, useAuth } from '@IJGN/potential'
import { useNavigate } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { type ReactNode, useEffect, useState } from 'react'
import { useIsAdmin, useIsCompanyPerson } from '~/lib/role'
import { useSharedData } from './context/SharedDataProvider'
import Header from './layout/header'
import Sidebar from './layout/sidebar'
import CenterLoading from './routes/components/Loading/Password'

interface AuthContainerProps {
  children: ReactNode
}

// ログインページは除き（再レンダリングを防ぐ目的）、この関数ではアクセスの権限処理は行わず、それらは個々のページで行う。
// ヘッダーとサイドバーの表示を制御する。
const AuthContainer = ({ children }: AuthContainerProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { user, authWaiting } = useAuth()
  const { company, isSharedDataLoading } = useSharedData()
  const isAdmin = useIsAdmin()
  const isCompanyPerson = useIsCompanyPerson()
  const [mainNode, setMainNode] = useState<React.ReactNode>(<CenterLoading />)
  const { survey: survey_code, company: company_code } = useParams<{
    survey: string
    company: string
  }>()

  Sentry.setUser({
    id: user?.id,
  })
  Sentry.setContext('extra_info', {
    isAdmin: isAdmin,
  })

  const pageLogin =
    location.pathname === '/' ||
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/admin/login') ||
    location.pathname.startsWith('/set_initial_password') ||
    location.pathname.startsWith('/admin/set_initial_password') ||
    location.pathname.startsWith('/reset_password') ||
    location.pathname.startsWith('/admin/reset_password') ||
    location.pathname.startsWith('/forgot_password') ||
    location.pathname.startsWith('/admin/forgot_password') ||
    location.pathname.startsWith('/expired_token') ||
    location.pathname.startsWith('/admin/verify_code') ||
    location.pathname.startsWith('/verify_code')
  const pageSurveyAnswer = location.pathname.startsWith('/q/')
  const pageSurveyReport = location.pathname.startsWith(`/companies/${company_code}/surveys/${survey_code}/report`)
  const rootPath = location.pathname === '/'

  useEffect(() => {
    if (authWaiting || isSharedDataLoading) {
      setMainNode(<CenterLoading />)
      return
    }
    if (pageLogin && user) {
      if (isAdmin) {
        setLatestAdminLogin()
      } else {
        removeLatestAdminLogin()
      }
      const redirectTo = new URLSearchParams(location.search).get('redirect_to') || ''
      const path = decodeURIComponent(redirectTo)
      // 他のサイトに飛ばされないように
      const safePath = path.startsWith('/') ? path : null
      if (safePath) {
        navigate(safePath)
        return
      }
      if (isCompanyPerson && company) {
        navigate(`/companies/${company.code}/dashboard`)
        return
      }
      if (isAdmin) {
        navigate('/admin/dashboard')
        return
      }
    }
    setMainNode(
      <>
        {!(rootPath || pageLogin || pageSurveyAnswer || pageSurveyReport) && (
          <>
            <Header />
            <Sidebar />
          </>
        )}
        {children}
      </>,
    )
  }, [
    user,
    authWaiting,
    pageLogin,
    children,
    isAdmin,
    navigate,
    isCompanyPerson,
    pageSurveyAnswer,
    pageSurveyReport,
    company,
    isSharedDataLoading,
    rootPath,
    location.search,
  ])

  return <>{mainNode}</>
}
export default AuthContainer
